import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import SignupBanner from "../components/signupBanner"

import { graphql, Link } from "gatsby" // to query for image data

const RoadPage = ({ data }) => (
  <Layout>
    <SEO
      title="Truck loading calculator"
      description="Optimized load plans with our 3D trailer loading software. Handles routing, OoG, pallets, and axle weights. From Excel to interactive loadplan in a few clicks."
    />
    <Hero
      image={data.heroImage}
      title="Truck Loading"
      body="Create interactive load plans with our road trailer calculator. Quick and easy"
      className="bg-white"
      customers={[
        data.dsv,
        data.kn,
        data.geodis,
        data.bollore,
        data.nippon,
        data.dhl,
      ]}
    />
    <hr />
    <FeaturesWithImages
      features={[
        {
          title: "Multiple Trailer Types",
          image: data.multipleTrailerTypes,
          content: (
            <span>
              Cargo-Planner comes packed with a large set of pre-made trailers,
              called the{" "}
              <Link
                to="/equipment-library/"
                className="text-blue-900 hover:underline"
              >
                equipment library
              </Link>{" "}
              and lets you pick multiple trailer types to load your cargoes. Our
              load-planning tool can optimize the utilization of trailers, the
              cost of shipping, or load them in sets or road trains. It's up to
              you.
            </span>
          ),
        },
        {
          title: "Custom trailers",
          image: data.customTrailer,
          content: (
            <span>
              With the Container Builder you can create custom trailers. It
              doesn't matter if it's an <b>extendable</b> Double Drop Trailer,
              or a custom supertrailer with <b>8 axles</b> and{" "}
              <b>multiple floors</b>. We have got you covered
            </span>
          ),
        },
        {
          title: "Road trains",
          image: data.roadTrains,
          content: (
            <span>
              With our new <b>Set Builder</b>, our software now supports the creation of configurable road trains. 
              This means that users can easily customize the composition of their road trains by selecting and combining different sets of vehicles to meet their specific needs.
              Cargo-Planner will load your road train with your transportation requirements in seconds.
            </span>
          ),
        },
        {
          title: "Routing",
          image: data.routing,
          content: (
            <span>
              Set destinations on your cargo, and Cargo-Planner will both find
              the optimal route and set the load order of the trailer so that
              each stop has easy access to the cargo ready for drop-off.
            </span>
          ),
        },
        {
          title: "Out of Gauge Cargoes",
          image: data.out_of_gauge,
          content: (
            <span>
              Cargo-Planner can handle out-of-gauge cargoes with ease. It
              doesn't matter if you have oversized cargoes or requirements on
              legal weight. Our software will minimize the number of trailers
              shipped as OoG, to reduce the number permits needed.
            </span>
          ),
        },
        {
          title: "Axle weight limitations",
          image: data.axles,
          content: (
            <span>
              If you have axle weight limitations, you can quickly set these
              limitations for the front and rear axle groups. Cargo-Planner will
              then make sure these limits are not exceeded for the generated
              load plan.{" "}
              <b>
                The tool will also try to reorganize cargoes on the trailer in
                order to achieve the highest utilization
              </b>{" "}
              given the restrictions.
            </span>
          ),
        },
        {
          title: "Pipes, drums and boxes",
          image: data.pipes,
          content: (
            <span>
              Cargo-Planner helps you plan different types of cargo, like pipes
              or drums, and will find the optimized solutions even if you mix
              types.
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
    <Benefits></Benefits>
    <SignupBanner></SignupBanner>
  </Layout>
)

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  {
    bollore: file(relativePath: { eq: "customers/bollore.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dhl: file(relativePath: { eq: "customers/dhl.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    dsv: file(relativePath: { eq: "customers/dsv.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    geodis: file(relativePath: { eq: "customers/geodis.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    kn: file(relativePath: { eq: "customers/kn.jpg" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    nippon: file(relativePath: { eq: "customers/nippon.png" }) {
      base
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { grayscale: true }
          layout: FULL_WIDTH
        )
      }
    }
    heroImage: file(
      relativePath: {
        eq: "containers/truck-and-trailer-with-palletized-goods-new.png"
      }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    axles: file(
      relativePath: { eq: "containers/flatbed-with-oversized-cargoes.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }

    pipes: file(relativePath: { eq: "containers/pipes-on-truck-2.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }

    multipleTrailerTypes: file(
      relativePath: { eq: "loadplans/multiple-trailer-types.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 100, layout: CONSTRAINED)
      }
    }
    customTrailer: file(relativePath: { eq: "containers/custom-trailer.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    roadTrains: file(relativePath: { eq: "screens/road-train-set-builder.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    out_of_gauge: file(
      relativePath: { eq: "containers/oversized-cargo-on-stepdeck.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    routing: file(relativePath: { eq: "docs/destinations-truck.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default RoadPage
